<template>
	<div>
		<page-bar name="Posts"></page-bar>
		<v-container fluid>
			<v-row class="mx-md-2">
				<v-spacer></v-spacer>
				<v-col cols="12">
					<v-card>
						<v-toolbar>
							<v-btn-toggle @change="textChanged" multiple>
								<v-btn>
									<v-icon>mdi-format-bold</v-icon>
								</v-btn>
								<v-btn>
									<v-icon>mdi-format-italic</v-icon>
								</v-btn>
							</v-btn-toggle>
						</v-toolbar>

						<v-container fluid>
							<editor-content :editor="editor" class="ma-2"/>
						</v-container>
					</v-card>

				</v-col>
				<v-spacer></v-spacer>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import PageBar from '@/components/global/PageBar'

const textMods = ['Bold', 'Italic']

export default {
	name: 'BlogPostsIndex',
	components: {EditorContent, PageBar},
	data: () => ({
		editor: null,
		textToggles: [],
	}),
	mounted() {
		this.editor = new Editor({
			content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
			extensions: [
				StarterKit,
			],
		})
	},
	beforeDestroy() {
		this.editor.destroy()
	},
	methods: {
		textChanged(data) {
			data.forEach(d => {
				if (this.textToggles.indexOf(d) === -1) {
					this.editor.chain().focus()[`toggle${textMods[d]}`]().run()
				}
			})
			this.textToggles.forEach(d => {
				if (data.indexOf(d) === -1) {
					this.editor.chain().focus()[`toggle${textMods[d]}`]().run()
				}
			})
			this.textToggles = [...data]
		}
	}
}

</script>

<style scoped>

</style>
